/* @flow */

import React, { useState, useEffect } from "react";
import { useData } from "crustate/react";
import { QuoteData } from "state/data";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";

import WarningIcon from "icons/redeem-warning.svg";
import styles from "./styles.scss";

const PurchaseLimitMessage = (): React$Node => {
  const t = useTranslate();
  const quoteData = useData(QuoteData);
  const [isActive, setIsActive] = useState(false);
  const grandTotal = quoteData && quoteData.data ? quoteData.data.grandTotal : null;
  const paymentLimit = 1500;

  useEffect(() => {
    if (grandTotal && grandTotal.incVat > paymentLimit) {
      setIsActive(true);
    }
    else {
      setIsActive(false);
    }
  }, [grandTotal]);

  if (!grandTotal) {
    return null;
  }

  return (
    <div className={cn(styles.block, {
      [styles.blockActive]: isActive,
    })}
    >
      <div className={styles.infoSection}>
        <WarningIcon />
        <div className={styles.infoText}>
          <span>{t("PAYMENT_LIMIT.TEXT_ONE", { amount: paymentLimit + " " + t("LOCALE.CURRENCY_SHORT") })}</span>
          <br />
          <span dangerouslySetInnerHTML={{ __html: t("PAYMENT_LIMIT.TEXT_TWO") }} />
        </div>
      </div>
    </div>
  );
};

export default PurchaseLimitMessage;
