/* @flow */

import type { Node as ReactNode } from "react";
import React from "react";
import { Wrapper } from "@crossroads/ui-components";

import styles from "./styles.scss";

type Props = {
  children: ReactNode,
  right: ReactNode,
};

const Container = ({ children, right }: Props): React$Node => {
  return (
    <Wrapper className={styles.checkoutContainer}>
      <div className={styles.left}>
        {children}
      </div>
      <div className={styles.right}>
        {right}
      </div>
    </Wrapper>
  );
};

export default Container;
