/* @flow */

import React from "react";
import cn from "classnames";
import { useHistory } from "react-router";
import { useData } from "crustate/react";
import { QuoteData } from "state/data";
import CartIcon from "icons/cart.svg";

import styles from "./styles.scss";

type Props = {
  className: string,
  isOpen: boolean,
  openMiniCart: () => void,
};

const CartCounter = ({ className = "", isOpen, openMiniCart }: Props): React$Node => {
  const data = useData(QuoteData);
  const history = useHistory();
  const onCheckout = history.location.pathname.includes("/checkout");
  const cartCount =
    data.data ?
      data.data.items.reduce((a, { qty }) => a + qty, 0) :
      0;

  if (cartCount > 0) {
    return (
      <span
        className={cn(
          styles.cartCounter,
          { [styles.active]: onCheckout || isOpen },
          className
        )}
        onClick={() => {
          if (onCheckout) {
            history.goBack();
          }
          else if (cartCount) {
            openMiniCart();
          }
          else {
            history.push("/checkout");
          }
        }}
      >
        <div>
          <span className={styles.count}>
            {cartCount}
          </span>
          <CartIcon className={styles.icon} />
        </div>
      </span>
    );
  }

  return (
    <div className={cn(styles.cartCounter, className)}>
      <CartIcon />
    </div>
  );
};

export default CartCounter;
