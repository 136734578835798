/* @flow */

import type { Quote } from "shop-state/types";

import React from "react";

import Free from "./Free";
import CollectorCheckout from "./CollectorCheckout";
import HashDialouge from "../../../app/HashDialouge";
import { CmsData } from "state/data";

type Props = {
  quote: Quote,
};

const Overview = ({ quote }: Props): React$Node => {
  if (quote.grandTotal.incVat === 0) {
    return (
      <CmsData.Provider url="terms">
        <Free />
      </CmsData.Provider>
    );
  }

  return (
    <>
      <HashDialouge />
      <CollectorCheckout />
    </>
  );
};

export default Overview;
