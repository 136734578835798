/* @flow */

import type { RouterHistory as History, Location } from "react-router";

import React from "react";
import { Route, Switch } from "react-router";
import Redirect from "components/Redirect";
import { useData } from "crustate/react";
import {
  RouteData, OrderData, HomeData, BrandData, SearchData,
  PopularData, AllProductsData, AdventCalendarData,
} from "state/data";
import { useTranslate } from "@awardit/react-use-translate";
import { PAGE_SIZE } from "effects/route";
import { StoreInfoContext } from "entrypoint/shared";
import { parseParams } from "helpers/location-search-string";
import { locationWithDefaults } from "helpers/hooks/use-update-product-list";
import { useUi } from "helpers/ui";
import Product, { HintProduct } from "pages/Product";
import ProductCard from "components/ProductCard";
import { ProductListViewWrapper, ProductListView, HintProductListView } from "@crossroads/shop-views";
import NotFound from "pages/NotFound";
import CmsPage from "pages/CmsPage";
import Success from "pages/Success";
import Loading from "pages/Loading";
import Search from "pages/Search";
import AdventCalendarHero from "pages/AdventCalendar/Hero";
import AdventCalendar from "pages/AdventCalendar";
import Home from "pages/Home";
import FilterIcon from "icons/filter_icon.svg";
import SortIcon from "icons/sort_icon.svg";

type RoutesProps = {
  location: Location,
};

type RouteViewProps = {
  location: Location,
  history: History,
};

const RouteView = ({ location, history }: RouteViewProps): React$Node => {
  const t = useTranslate();
  const data = useData(RouteData);
  const homeData = useData(HomeData);
  const onAdventCalendarCategoryPath = location.pathname.includes("julkalender");

  if (data.state === "LOADING") {
    if (data.hint) {
      switch (data.hint.type) {
        case "product":
          return <HintProduct product={data.hint.product} />;
        case "category":
          return (
            <HintProductListView
              title={data.hint.category.name}
              StoreInfoContext={StoreInfoContext}
            />
          );
        case "cms_page":
          return <CmsPage cmsPage={data.hint.cmsPage} />;
        default:
      }
    }

    return <Loading />;
  }

  if (!data.route) {
    // TODO: Check error msg
    return <NotFound />;
  }

  switch (data.route.type) {
    case "product":
      return <Product product={data.route.product} />;
    case "category":
      const title = onAdventCalendarCategoryPath &&
      data.route.category.heroHeading !== null &&
      data.route.category.heroHeading !== undefined ?
        data.route.category.heroHeading : data.route.category.name;
      return (
        <ProductListView
          hasFilters
          hasPagination
          hasCategorySubNavigation={false}
          variant="category"
          history={history}
          category={data.route.category}
          home={homeData.state === "LOADED" ? homeData.data : {}}
          productList={data.route.category.products}
          title={title}
          isUpdating={data.state === "UPDATING"}
          pageSize={PAGE_SIZE}
          StoreInfoContext={StoreInfoContext}
          uiContext={useUi}
          currentBreadcrumb={data.route.category.name}
          productsLink={location.pathname}
          sortIcon={<SortIcon style={{ height: "36px", width: "36px" }} />}
          filterIcon={<FilterIcon style={{ height: "36px", width: "36px" }} />}
          AdventCalendarHeroComponent={AdventCalendarHero}
          ProductCardComponent={ProductCard}
          onAdventCalendarPath={t("ADVENT_CALENDAR.CATEGORY_URL")}
        />
      );
    case "cms_page":
      return <CmsPage cmsPage={data.route.cmsPage} />;
    case "redirect":
      return <Redirect to={data.route.url} location={location} />;
    default:
      return null;
  }
};

const Routes = ({ location }: RoutesProps): React$Node => {
  const t = useTranslate();

  return (
    <Switch>
      <Route
        exact path="/" render={() => (
          <HomeData.Provider>
            <Home />
          </HomeData.Provider>
        )} />

      <Route
        path="/success"
        render={() => (
          <OrderData.Provider>
            <Success />
          </OrderData.Provider>
        )} />

      <Route
        path="/brand/:brand"
        render={({ match, location: { search } }) => {
          const { page } = parseParams(search);
          const brand = match.params.brand || "";

          return (
            <BrandData.Provider
              location={locationWithDefaults(location)}
              name={brand}
              brand={decodeURIComponent(brand)}
              page={page}
            >
              <ProductListViewWrapper
                variant="brands"
                data={BrandData}
                pageSize={PAGE_SIZE}
                StoreInfoContext={StoreInfoContext}
                uiContext={useUi}
                sortIcon={<SortIcon style={{ height: "36px", width: "36px" }} />}
                filterIcon={<FilterIcon style={{ height: "36px", width: "36px" }} />}
                ProductCardComponent={ProductCard}
              />
            </BrandData.Provider>
          );
        }} />

      <Route
        exact path="/popular-products"
        render={({ location: { search } }) => {
          const { page } = parseParams(search);
          return (
            <HomeData.Provider>
              <PopularData.Provider
                location={locationWithDefaults(location)}
                page={page}
              >
                <ProductListViewWrapper
                  variant="popular"
                  data={PopularData}
                  pageSize={PAGE_SIZE}
                  StoreInfoContext={StoreInfoContext}
                  uiContext={useUi}
                  title={t("POPULAR.POPULAR_PRODUCTS")}
                  sortIcon={<SortIcon style={{ height: "36px", width: "36px" }} />}
                  filterIcon={<FilterIcon style={{ height: "36px", width: "36px" }} />}
                  ProductCardComponent={ProductCard}
                />
              </PopularData.Provider>
            </HomeData.Provider>
          );
        }} />

      <Route
        exact path="/all-products"
        render={({ location: { search } }) => {
          const { page } = parseParams(search);

          return (
            <AllProductsData.Provider
              location={locationWithDefaults(location)}
              page={page}
            >
              <ProductListViewWrapper
                variant="allproducts"
                data={AllProductsData}
                pageSize={PAGE_SIZE}
                StoreInfoContext={StoreInfoContext}
                uiContext={useUi}
                title={t("ALL_PRODUCTS.TITLE")}
                sortIcon={<SortIcon style={{ height: "36px", width: "36px" }} />}
                filterIcon={<FilterIcon style={{ height: "36px", width: "36px" }} />}
                ProductCardComponent={ProductCard}
              />
            </AllProductsData.Provider>
          );
        }} />

      <Route
        exact
        path="/advent-calendar"
        render={({ location: { search } }) => {
          const { password, testDate } = parseParams(search);
          return (
            <AdventCalendarData.Provider
              testDate={typeof testDate === "string" ? testDate : null}
              password={typeof password === "string" ? password : null}
            >
              <HomeData.Provider>
                <AdventCalendar />
              </HomeData.Provider>
            </AdventCalendarData.Provider>
          );
        }} />

      <Route
        path="/search/:query"
        render={({ match, location: { search } }) => {
          const { page } = parseParams(search);

          const query = decodeURIComponent(match.params.query || "");

          return (
            <SearchData.Provider
              location={locationWithDefaults(location)}
              page={page}
              query={query}
              name={`search_${query}`}
            >
              <Search query={query} />
            </SearchData.Provider>
          );
        }}
      />

      <Route
        path="/(.+)"
        render={({ location: { pathname, search, state }, history }) => {
          const { page } = parseParams(search);

          return (
            <RouteData.Provider
              location={locationWithDefaults(location)}
              url={pathname}
              name={`route_${pathname}`}
              page={page}
              hint={state && state.hint}
            >
              <HomeData.Provider>
                <RouteView location={location} history={history} />
              </HomeData.Provider>
            </RouteData.Provider>
          );
        }} />
    </Switch>
  );
};

const AppRoutes = (): React$Node => <Route component={Routes} />;

export default AppRoutes;
