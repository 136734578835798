/* @flow */

import type { Category } from "shop-state/types";

import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { Wrapper } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

const AdventCalendarHero = ({ category }: { category: Category }): React$Node => {
  const t = useTranslate();

  return (
    <Wrapper className={styles.hero}>
      <div className={styles.banner}>
        <div
          className={styles.bannerSmall}
          style={{ backgroundImage: `url(${category.clsCatfieldsGeneralImgS?.x2 || ""})` }}
        />
        <div
          className={styles.bannerMedium}
          style={{ backgroundImage: `url(${category.clsCatfieldsGeneralImgM?.x2 || ""})` }}
        />
        <div
          className={styles.bannerLarge}
          style={{ backgroundImage: `url(${category.clsCatfieldsGeneralImgL?.x2 || ""})` }}
        />
      </div>
      <Breadcrumbs
        className={styles.breadcrumbs}
        links={[{
          title: t("ADVENT_CALENDAR.TEXT"),
          pathname: `/${t("ADVENT_CALENDAR.CATEGORY_URL")}`,
        }]}
        current={t("ADVENT_CALENDAR.DOOR_TITLE", { day: category.name })} />
      {category.metaTitle && (
        <h2>{category.metaTitle}</h2>
      )}
    </Wrapper>
  );
};

export default AdventCalendarHero;
