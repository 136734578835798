/* @flow */

import type { QuoteItem, ProductSubset, ProductCardProduct } from "shop-state/types";

import React, { useState, useContext, useEffect } from "react";
import { useClient } from "entrypoint/shared";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { AnalyticsContext } from "@crossroads/analytics";
import QtyPicker from "components/QtyPicker";
import { QuoteData } from "state/data";
import CustomerServiceLink from "pages/Checkout/CustomerServiceLink";
import { useSendMessage, useData } from "crustate/react";
import { removeQuoteItem, updateQuoteItemQty } from "@crossroads/shop-state/quote";
import { Dialogue, Carousel, Combined, Dots } from "@crossroads/ui-components";
import ButtonLink from "components/ButtonLink";
import Container from "pages/Checkout/Container";
import CartSummary from "components/CartSummary";
import DiscountCode from "pages/Checkout/DiscountCode";
import useFormat from "helpers/hooks/use-format";
import CloseIcon from "icons/close.svg";
import ProductCard from "components/ProductCard";
import { upsell } from "queries";
import useBrowserDimensions from "helpers/hooks/use-browser-dimensions";
import ArrowIcon from "icons/arrow.svg";
import PurchaseLimitMessage from "pages/Checkout/PurchaseLimitMessage";

import styles from "./styles.scss";

const QUOTE_ITEM_UPDATING_STATES = new Set([
  "UPDATING_ITEM",
  "REMOVING_ITEM",
]);

const Cart = (): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const gaContext = useContext(AnalyticsContext);
  const quote = useData(QuoteData);
  const client = useClient();
  const { formatPrice } = useFormat();
  const [open, setOpen] = useState(true);
  const [activeItem, setActiveItem] = useState<QuoteItem | null>(null);
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
  const [updatingQuote, setUpdatingQuote] = useState<boolean>(false);
  const { width: browserWidth } = useBrowserDimensions();
  const isDesktop = browserWidth > 800;
  const [upsellProducts, setUpsellProducts] = useState<Array<ProductCardProduct>>([]);

  useEffect(() => {
    if (QUOTE_ITEM_UPDATING_STATES.has(quote.state)) {
      setUpdatingQuote(true);
    }
    else {
      setUpdatingQuote(false);
    }
  }, [quote.state]);

  const fetchUpsellProducts = async () => {
    try {
      const upsellProductData = await client(upsell);
      const upsellProductItems = upsellProductData.upsellProducts.category.products.items ?
        upsellProductData.upsellProducts.category.products.items : [];

      setUpsellProducts(upsellProductItems);
    }
    catch (e) {
      console.log(e);
    }
  };

  const sendGAEvent = (product: ProductSubset, diff) => {
    gaContext.registerModifyCart({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: Math.abs(diff),
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    }, diff > 0 ? "add_to_cart" : "remove_from_cart", product.price.incVat);
  };

  useEffect(() => {
    fetchUpsellProducts();

    if (quote.data) {
      gaContext.viewedCart(quote.data.grandTotal.incVat, quote.data.items.map(item => {
        return {
          sku: item.sku ?? item.product.sku,
          name: item.product.name,
          price: {
            exVat: item.rowTotal.exVat,
            incVat: item.rowTotal.incVat,
            vat: item.rowTotal.incVat - item.rowTotal.exVat,
          },
          qty: item.qty,
          attributes: {
            manufacturer: item.product.attributes.manufacturer,
          },
          categories: item.product.categories,
        };
      }));
    }
  }, []);

  if (quote.state === "LOADING") {
    return null;
  }

  const remove = (item: QuoteItem) => {
    sendGAEvent({
      sku: item.product.sku,
      name: item.product.name,
      price: item.product.price,
      qty: item.qty,
      attributes: {
        manufacturer: item.product.attributes.manufacturer,
      },
      categories: item.product.categories,
    }, -item.qty);

    sendMessage(removeQuoteItem(item.itemBuyRequest));
  };

  const confirmRemove = (item: QuoteItem) => {
    setConfirmDialogueOpen(true);
    setActiveItem(item);
  };

  const processingItem = typeof quote.processingItem !== "undefined" ? quote.processingItem : null;

  if (!quote.data) {
    return null;
  }

  const { items, coupon } = quote.data;

  return (
    <Container
      right={
        <div>
          <CartSummary open={open} setOpen={setOpen}>
            <div className={styles.submitButtonContainer}>
              <ButtonLink
                className={styles.submitButton}
                variant="primary"
                to="/checkout/overview"
              >
                {t("CART.TO_CHECKOUT")}
              </ButtonLink>
            </div>
          </CartSummary>

          <CustomerServiceLink />
        </div>
      }
    >
      {confirmDialogueOpen &&
      <Dialogue
        className={styles.confirmDialogue}
        open={confirmDialogueOpen}
        setOpen={() => setConfirmDialogueOpen(false)}
        title={t("CART.REMOVE_PRODUCT")}
        closeIcon={<div className={styles.closeIconWrapper}><CloseIcon /></div>}
        primaryAction={t("CART.REMOVE_APPROVE")}
        secondaryAction={t("CONFIRM_DIALOG.CANCEL")}
        onPrimaryAction={() => {
          if (activeItem) {
            setConfirmDialogueOpen(false);
            remove(activeItem);
          }
        }}
        onSecondaryAction={() => setConfirmDialogueOpen(false)}
      >
        {t("CART.CONFIRM_TEXT", {
          itemName: `${activeItem?.product.name || ""}, ${activeItem?.product.attributes.manufacturer || ""}`,
        })}
      </Dialogue>}
      <div className={styles.cart}>
        {items.map(x => {
          const product = x.configOption ? {
            ...x.product,
            ...x.configOption.product,
            categories: x.product.categories,
          } : x.product;

          const { manufacturer } = product.attributes;
          return (
            <div
              key={x.itemBuyRequest}
              className={
                cn(styles.item, {
                  [styles.processing]: processingItem === x.itemBuyRequest,
                  [styles.disabled]: quote.state === "UPDATING_ITEM",
                })}
            >
              <div className={styles.left}>
                <div style={{ display: "flex", position: "static" }}>
                  <img
                    className={styles.image}
                    alt={product.name}
                    src={product.attributes.image?.x1}
                  />
                  <div className={styles.info}>
                    <div>
                      <Link
                        to={{
                          pathname: product.url,
                          state: { hint: {
                            type: "product",
                            product,
                            image: product.attributes.image?.x1,
                          } },
                        }}
                        className={styles.name}
                      >
                        {product.name}
                      </Link>

                      <Link
                        to={{
                          pathname: `/brand/${encodeURIComponent(manufacturer)}`,
                          state: { hint: { type: "category", category: { name: manufacturer } } },
                        }}
                        className={styles.brand}
                      >
                        {manufacturer}
                      </Link>

                      {x.bundleOptions &&
                        <ul className={styles.bundleOptions}>
                          {x.bundleOptions.map(o => o.products.map(p =>
                            <li key={o.title}>{p.product.name}</li>
                          ))}
                        </ul>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.right}>
                <QtyPicker
                  className={styles.qtyPicker}
                  value={x.qty}
                  min={0}
                  setValue={(v: number) => {
                    if (v > 0) {
                      sendMessage(updateQuoteItemQty(x.itemBuyRequest, v));
                      sendGAEvent({
                        ...product, qty: Math.abs(v - x.qty),
                      }, v - x.qty);
                    }
                    else {
                      confirmRemove(x);
                    }
                  }} />
                <div className={styles.priceWrapper}>
                  <p>{formatPrice((x.qty * x.product.price.incVat) || 0)}</p>
                </div>
              </div>
            </div>
          );
        })}
        <DiscountCode
          disabled={updatingQuote}
          coupon={coupon}
          setSummaryOpen={setOpen}
        />
        <PurchaseLimitMessage />
      </div>
      {upsellProducts &&
      upsellProducts.length > 0 &&
        <>
          <h2>{t("CART.OTHERS_ALSO_BOUGHT")}</h2>
          <div className={styles.carouselContainer}>
            <Carousel
              autoplay
              items={upsellProducts.map((p, i) => (
                <ProductCard
                  key={i}
                  product={p}
                  position={i}
                  className={styles.carouselItem}
                />
              ))}
              slidesToScroll={isDesktop ? 4 : 2}
              slidesToShow={isDesktop ? 4 : 2}
              timer={7500}
            >
              {isDesktop ?
                <Combined icon={<ArrowIcon />} className={styles.carouselControls} /> :
                <Dots className={styles.carouselControls} />
              }
            </Carousel>
          </div>
        </>
      }
    </Container>
  );
};

export default Cart;
