/* @flow */

import React, { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { getQuoteData } from "state/quote";
import { useData } from "crustate/react";
import { QuoteData } from "state/data";
import { AnalyticsContext } from "@crossroads/analytics";

import Cart from "pages/Checkout/Cart";
import Overview from "pages/Checkout/Overview";
import useCheckoutSteps from "helpers/hooks/use-checkout-steps";

const CheckoutRoutes = (): React$Node => {
  const quoteState = useData(QuoteData);
  const quote = getQuoteData(quoteState);
  const gaContext = useContext(AnalyticsContext);
  const [visited, setVisited] = useState([]);
  const { currentStep } = useCheckoutSteps();
  const step = currentStep.key;

  useEffect(() => {
    setVisited([...visited, step]);
  }, []);

  useEffect(() => {
    if (step === 0) {
      return;
    }

    if (step === 2) {
      if (quote) {
        gaContext.registerBeginCheckoutProcess(
          quote.items,
          quote.grandTotal.incVat
        );
      }
    }

    gaContext.registerCheckoutStep(
      step,
      currentStep.id
    );
  }, [step]);

  if (quoteState.state === "LOADING" || !quote) {
    return null;
  }

  return (
    <Switch>
      <Route exact path="/checkout" component={Cart} />
      <Route exact path="/checkout/overview" render={() => <Overview quote={quote} />} />
    </Switch>
  );
};

export default CheckoutRoutes;
