/* @flow */

import type { AdventCalendarDoorToday } from "state/advent-calendar";

import React from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { AdventCalendarData, HomeData } from "state/data";
import Loading from "pages/Loading";
import ProductList from "components/ProductList";
import { useBreakpoints } from "helpers/hooks/use-breakpoints";
import { AdventCalendarDoors, Wrapper, Button } from "@crossroads/ui-components";
import styles from "./styles.scss";

const Closed = ({ text }: { text: string }) => <span>{text}</span>;

const Today = ({
  door: { day, category },
}: { door: AdventCalendarDoorToday }) => {
  const t = useTranslate();
  const home = useData(HomeData);
  const { getNumberOfDisplayedItems } = useBreakpoints(styles);
  const productsToShow = getNumberOfDisplayedItems({ tiny: 1, small: 2, medium: 3 }, 4);
  const parentCategoryBanner =
    home.state === "LOADED" &&
    home.data.adventCalendar?.category.clsCatfieldsGeneralImgL !== undefined &&
    home.data.adventCalendar.category.clsCatfieldsGeneralImgL !== null ?
      home.data.adventCalendar.category.clsCatfieldsGeneralImgL : null;
  const {
    url, name, description, bannerSmall, bannerMedium, bannerLarge, image, products,
  } = category;

  return (
    <div className={styles.today}>
      <Wrapper>

        <div className={styles.todayBanner}>
          {bannerSmall && bannerMedium && bannerLarge ?
            <>
              <div className={styles.todaySmallBanner} style={{ backgroundImage: `url(${bannerSmall.x2 || ""})` }} />
              <div className={styles.todayMediumBanner} style={{ backgroundImage: `url(${bannerMedium.x2 || ""})` }} />
              <div className={styles.todayLargeBanner} style={{ backgroundImage: `url(${bannerLarge.x2 || ""})` }} />
            </> :
            <div
              className={cn(styles.todayLargeBanner,
                styles.todayMediumBanner,
                styles.todaySmallBanner)}
              style={{ backgroundImage: `url(${parentCategoryBanner?.x2 || ""})` }}
            />
          }
        </div>
        <div className={styles.todayContainer}>
          <div className={styles.todayContent}>
            <h3>{t("ADVENT_CALENDAR.DOOR_TITLE", { day })}</h3>
            <h2>{name}</h2>
          </div>
        </div>
        <div className={styles.todayProductList}>
          <ProductList
            products={products.items.slice(0, productsToShow)}
          />
        </div>
        {products.totalCount > 4 &&
          <Link
            to={{
              pathname: url,
              state: { hint: { type: "category", category: { name, url, image, description } } },
            }}
            className={styles.todayViewAll}
          >
            <Button variant="ghost">
              {t("ADVENT_CALENDAR.VIEW_ALL_PRODUCTS")}
            </Button>
          </Link>
        }
      </Wrapper>
    </div>
  );
};

const AdventCalendar = (): React$Node => {
  const t = useTranslate();
  const data = useData(AdventCalendarData);

  if (data.state !== "LOADED") {
    return <Loading />;
  }

  let today = null;
  data.data.forEach(day => {
    if (day.__typename === "AdventCalendarDoorToday") {
      today = day;
    }
  });

  const openDays = [];
  data.data.forEach(day => {
    if (day.__typename !== "AdventCalendarDoorLocked") {
      openDays.push(day);
    }
  });

  if (openDays.length === 0) {
    return (
      <div className={cn(styles.landing, styles.fullSize)}>
        <div className={styles.landingContainer}>
          <h1>{t("ADVENT_CALENDAR.LANDING.HEADING")}</h1>
          <p>{t("ADVENT_CALENDAR.LANDING.TEXT")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <Helmet title={t("ADVENT_CALENDAR.TEXT")} />

      {(openDays.length > 0 && today) &&
        <Today door={today} />
      }

      <Wrapper>
        <AdventCalendarDoors
          closedSlot={<Closed text={t("ADVENT_CALENDAR.CALENDAR_CLOSED")} />}
          doors={data.data}
          buttonVariant="negative" />
      </Wrapper>
    </div>
  );
};

export default AdventCalendar;
